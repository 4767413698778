<template>
  <div>
    <b-card v-if="getDevMode">
      <div class="d-flex justify-content-between">
        <div class="flex-row">
          <h3 class="card-label font-weight-bolder text-dark">
            {{ $t("DEPARTMENT.HEADER") }}
          </h3>
          <span class="text-muted mt-3 font-weight-bold">{{
            $t("DEPARTMENT.SUBTITLE")
          }}</span>
        </div>
        <div class="card-toolbar">
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="refresh"
          >
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
            </span>
          </b-button>
          <b-button
            variant="primary"
            class="font-weight-bolder"
            v-b-toggle.department-list-sidebar
            >{{ $t("DEPARTMENT.BUTTON") }}</b-button
          >
          <b-sidebar
            ref="department-list-sidebar-ref"
            id="department-list-sidebar"
            backdrop
            right
            lazy
            :title="$t('DEPARTMENTFORM.HEADER')"
            sidebar-class="offcanvas"
            header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
            bg-variant="white"
          >
            <template v-slot:header-close>
              <button
                class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
              >
                <i class="ki ki-close icon-xs"></i>
              </button>
            </template>
            <div class="px-10 mt-5">
              <DepartmentForm
                v-slot:default="{}"
                :title="$t('DEPARTMENTFORM.FORM')"
                v-on:close="testClose"
              ></DepartmentForm>
            </div>
          </b-sidebar>
        </div>
      </div>
      <div>
        <b-alert
          variant="info"
          show="5"
          fade
          dismissible
          v-if="formData.length > 0"
        >
          {{ formData }}
        </b-alert>
      </div>

      <DepartmentPageTable :reload="reload"></DepartmentPageTable>
    </b-card>
    <b-card v-if="!getDevMode">
      <div class="d-flex justify-content-between flex-wrap"></div>
      <div class="flex-row">
        <h3 class="card-label text-center font-weight-bolder text-dark">
          Stay Tuned
        </h3>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { alert } from "sweetalert2";
import DepartmentForm from "@/modules/company/components/department/DepartmentPageForm";
import DepartmentPageTable from "@/modules/company/components/department/DepartmentPageTable";

export default {
  name: "DepartmentListPage",
  components: { DepartmentPageTable, DepartmentForm },
  mounted() {
    console.log("Employee List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.DEPARTMENT"),
        route: "/company/department/list",
      },
    ]);
  },
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    testClose(data) {
      console.log(data);
      this.formData = "Successfully added department";
      console.log(this.formData);

      const sideBar = this.$refs["department-list-sidebar-ref"];
      sideBar.hide();

      this.$store.dispatch("getDepartment").catch(() => {
        alert({
          title: "Failed",
        });
      });
    },

    refresh() {
      this.reload = !this.reload;
    },
  },
  computed: {
    getDevMode() {
      console.log("Ayam!");
      console.log("Ayam!");
      let mode = false;
      if (process.env.NODE_ENV === "development") {
        mode = true;
      }

      return mode;
    },
  },
};
</script>

<style scoped></style>
