<template>
  <div>
    <b-table
      ref="department-list-page-dt"
      id="department-list-page-dt"
      responsive="sm"
      :fields="fields"
      :items="getDepartment"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-40 symbol-light mr-5">
            <span class="symbol-label svg-icon svg-icon-secondary">
              <inline-svg
                class="h-100 align-self-end"
                src="/media/svg/icons/Home/Building.svg"
              />
            </span>
          </div>
          <div class="d-flex flex-column">
            <span class="font-weight-bolder text-h6">
              {{ data.item.name }}
            </span>
            <span class="text-muted"> {{ data.item.description }} </span>
          </div>
        </div>
      </template>

      <template v-slot:cell(actions)="">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3">
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeDetails"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items">
      <b-pagination
        pills
        v-model="items.current_page"
        :total-rows="items.total"
        :per-page="items.per_page"
        aria-controls="department-list-page-dt"
        @change="handlePageChange"
        size="lg"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { alert } from "sweetalert2";
import { mapState } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import swal from "sweetalert";

export default {
  name: "DepartmentPageTable",
  components: { AppLoader },
  props: ["reload"],
  mounted() {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        // Mapped column which contains the name of the department
        { key: "name", label: this.$t("EMPLOYEETABLE.DEPARTMENTNAME") },
      ],
    };
  },
  methods: {
    handlePageChange(value) {
      console.log(value);
      this.$store
        .dispatch("getDepartment", {
          page_number: value,
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    deleteEmployeeDetails() {
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.WARNING"),
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
          });
        }
      });
    },

    getDepartment() {
      return this.$store
        .dispatch("getDepartment")
        .then((response) => {
          console.log("Table has", response.data.data);
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
  },

  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.department.list.items,
    }),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["department-list-page-dt"].refresh();
    },
  },
};
</script>

<style scoped></style>
